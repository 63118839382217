import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useItemsContext } from '@/@presentation/contexts/schedulesContext';
import { Modal as AntModal, Typography, Form, Row, Col, Input, Divider } from 'antd';
import {
    Button,
    validateMessages,
} from '@presentation/components/form';
import style from './style';
import InputMask from 'react-input-mask';
import CheckCep from '@/services/viaCep';
import { Notification } from '@presentation/components/common';
import customer from '@/@core/modules/customer/infra/container.registry';
import { SearchCustomerInterface } from '@/@core/modules/customer/domain/customer.entity';
export interface IPayerProps {
    visible: boolean
    setVisible: React.Dispatch<React.SetStateAction<boolean>>
    data?: any
    allData?: any[]
    onlyRead?: boolean
    setReload: (arg: boolean) => void
    setItemBase: (arg: any) => void
    setDataPayerError: React.Dispatch<React.SetStateAction<boolean>>
    dataPayerError: Boolean
    setPagador: React.Dispatch<React.SetStateAction<string>>
}

export interface UpdateCustomerInterface {
    id?: number,
    personId?: number,
    scheduleId: number[],
    name: string
    email: string
    registry_code: string
    code: string
    address: {
        id?: number
        street: string
        number: string
        additional_details: string
        zipcode: string
        neighborhood: string
        city: string
        state: string
    }
}

const Payer = (props: IPayerProps) => {
    const navigate = useNavigate()
    const { setItems, setOrigin, items } = useItemsContext();

    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(false)

    const [customerName, setCustomerName] = useState('')
    const [customerId, setCustomerId] = useState(0)
    const [email, setEmail] = useState('')
    const [cep, setCep] = useState('')
    const [document, setDocument] = useState('')
    const [address, setAddress] = useState('')
    const [numberHome, setNumberHome] = useState('')
    const [complement, setComplement] = useState('')
    const [neighborhood, setNeighborhood] = useState('')
    const [state, setState] = useState('')
    const [city, setCity] = useState('')

    const [searchedCustomer, setSearchedCustomer] = useState<SearchCustomerInterface>();

    const setStatesByCustomer = (data: SearchCustomerInterface) => {
        if (data.payment_customer) {
            setCustomerId(data.payment_customer.id ?? 0)
            setCustomerName(data.payment_customer.person?.name ?? '')
            setEmail(data.payment_customer.person?.email ?? '')
            setCep(data.payment_customer.address?.zipcode ?? '')
            setDocument(data.payment_customer.person?.cpf ?? '')
            setAddress(data.payment_customer.address?.street ?? '')
            setNumberHome(data.payment_customer.address?.number ?? '')
            setComplement(data.payment_customer.address?.complement ?? '')
            setNeighborhood(data.payment_customer.address?.neighborhood ?? '')
            setState(data.payment_customer.address?.state ?? '')
            setCity(data.payment_customer.address?.city ?? '')

            if (!customerName || !email || !cep || !document || !address || !numberHome || !neighborhood || !state || !city) {
                props.setDataPayerError(true)
            }
        } else {
            setCustomerId(0)
            setCustomerName('')
            setEmail('')
            setCep('')
            setAddress('')
            setNumberHome('')
            setComplement('')
            setNeighborhood('')
            setState('')
            setCity('')
        }
    }

    const setCustumerByCpf = async (cpf: string) => {
        const result = await customer.search.execute(cpf)
        if (result?.payment_customer) {
            setSearchedCustomer(result)
            setStatesByCustomer(result)
        } else {
            setSearchedCustomer(undefined)
            setCustomerName('')
            setCustomerId(0)
            setEmail('')
            setCep('')
            setAddress('')
            setNumberHome('')
            setComplement('')
            setNeighborhood('')
            setState('')
            setCity('')
        }
    }

    const searchCustomerByCpf = async (initialCpf?: string) => {
        const formatCpf = document.replace(/\D/g, '')
        const formatInitialCpf = initialCpf?.replace(/\D/g, '')


        if (!initialCpf && !formatCpf) {
            return;
        }

        const isCPForCNPJ =
        formatCpf.length === 11 ||
        formatCpf.length === 14 ||
        formatInitialCpf?.length === 11 ||
        formatInitialCpf?.length === 14

        if (!isCPForCNPJ) {
            return;
        }

        if (formatInitialCpf) {
            await setCustumerByCpf(formatInitialCpf)
            return;
        }

        await setCustumerByCpf(formatCpf)
        return;

    }

    async function handleZipCode(zipcode: string) {
        if (zipcode.length > 7) {
            const address = await CheckCep(zipcode)

            setAddress(address.logradouro ?? '')
            setNeighborhood(address?.bairro ?? '')
            setState(address.uf ?? '')
            setCity(address?.localidade ?? '')
        }
    }

    useEffect(() => {
        console.log('props',props)
        setCustomerId(  props?.data[0]?.payerCustomer?.id ?? '')
        props?.setPagador && props?.setPagador(props?.data[0]?.payerCustomer?.name ?? 'Não informado')
        setCustomerName(props?.data[0]?.payerCustomer?.name ?? '')
        setEmail(       props?.data[0]?.payerCustomer?.email ?? '')
        setDocument(    props?.data[0]?.payerCustomer?.registry_code ?? '')
        setCep(         props?.data[0]?.payerCustomer?.address?.zipcode ?? props?.data[0]?.payerCustomer?.zipcode ?? '')
        setAddress(     props?.data[0]?.payerCustomer?.address?.street  ?? props?.data[0]?.payerCustomer?.street ?? '')
        setNumberHome(  props?.data[0]?.payerCustomer?.address?.number  ?? props?.data[0]?.payerCustomer?.number ?? '')
        setNeighborhood(props?.data[0]?.payerCustomer?.address?.neighborhood ?? props?.data[0]?.payerCustomer?.neighborhood ?? '')
        setState(       props?.data[0]?.payerCustomer?.address?.state ??  props?.data[0]?.payerCustomer?.state ?? '')
        setCity(        props?.data[0]?.payerCustomer?.address?.city ?? props?.data[0]?.payerCustomer?.city  ?? '')
        setComplement(  props?.data[0]?.payerCustomer?.address?.complement ?? props?.data[0]?.payerCustomer?.complement ?? '')
        
    }, [props.visible, props.data])


    const handleChangeDocument = (e: any) => {
        const { value } = e.target;
        setDocument(value);
        setError(false);
    };

    const determineMaskDocument = (str: string) => {
        const value = str.replace(/\D/g, '');
        return value.length > 11 ? '99.999.999/9999-99' : '999.999.999-999';
    };

    async function validateCNPJ(strDocument: string): Promise<boolean> {
        strDocument = strDocument.replace(/\D/g, '');
        if (strDocument === '00000000000000' || strDocument === '11111111111111' || strDocument === '22222222222222' || strDocument === '33333333333333' ||
            strDocument === '44444444444444' || strDocument === '55555555555555' || strDocument === '66666666666666' || strDocument === '77777777777777' ||
            strDocument === '88888888888888' || strDocument === '99999999999999' || strDocument.length !== 14) {
            return false;
        }

        let tamanho = strDocument.length - 2;
        let numeros = strDocument.substring(0, tamanho);
        let digitos = strDocument.substring(tamanho);
        let soma = 0;
        let pos = tamanho - 7;
        for (let i = tamanho; i >= 1; i--) {
            soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
            if (pos < 2) {
                pos = 9;
            }
        }
        let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
        if (resultado !== parseInt(digitos.charAt(0))) {
            return false;
        }

        tamanho = tamanho + 1;
        numeros = strDocument.substring(0, tamanho);
        soma = 0;
        pos = tamanho - 7;
        for (let i = tamanho; i >= 1; i--) {
            soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
            if (pos < 2) {
                pos = 9;
            }
        }
        resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
        if (resultado !== parseInt(digitos.charAt(1))) {
            return false;
        }

        return true;
    }

    async function validateCPF(strCPF: string): Promise<boolean> {
        strCPF = strCPF.replace(/\D/g, '')

        if (strCPF === '00000000000' || strCPF === '11111111111' || strCPF === '22222222222' || strCPF === '33333333333' ||
            strCPF === '44444444444' || strCPF === '55555555555' || strCPF === '66666666666' || strCPF === '77777777777' ||
            strCPF === '88888888888' || strCPF === '99999999999' || strCPF.length !== 11) {
            return false;
        }

        let Soma: number = 0;
        for (let i = 1; i <= 9; i++) {
            Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
        }

        let Resto = (Soma * 10) % 11;
        if (Resto === 10 || Resto === 11) {
            Resto = 0;
        }

        if (Resto !== parseInt(strCPF.substring(9, 10))) {
            return false;
        }

        Soma = 0;
        for (let k = 1; k <= 10; k++) {
            Soma = Soma + parseInt(strCPF.substring(k - 1, k)) * (12 - k);
        }

        Resto = (Soma * 10) % 11;
        if (Resto === 10 || Resto === 11) {
            Resto = 0;
        }

        if (Resto !== parseInt(strCPF.substring(10, 11))) {
            return false;
        }

        return true;
    }

    async function handleUpdatePayer() {
        if (customerName.length === 0 || email.length === 0 || document.length < 10 || cep.length < 7) {
            props.setDataPayerError(true)
            Notification.error({
                message: 'Por favor, verifique os dados informados!',
                duration: 3,
            });
            setError(true);
            return;
        }

        const validadeCpfOuCnpj = document.replace(/\D/g, '').length > 11 ? await validateCNPJ(document) : await validateCPF(document)

        if (!validadeCpfOuCnpj) {
            Notification.error({
                message: 'O CPF/CNPJ informado não é valido!',
                duration: 3,
            });
            return;
        }

        setError(false);

        const newCustomer: UpdateCustomerInterface = {
            id: customerId,
            scheduleId: props?.allData?.map(i => +i.id) || [props?.data?.id],
            name: customerName,
            email: email,
            registry_code: document,
            code: document,
            address: {
                street: address,
                number: numberHome,
                additional_details: complement,
                zipcode: cep,
                neighborhood: neighborhood,
                city: city,
                state: state
            }
        };

        /*
        if (searchedCustomer?.payment_customer?.id) {
            newCustomer.id = searchedCustomer.payment_customer.id;
        }
        */
        if (searchedCustomer?.payment_customer?.address?.id) {
            newCustomer.address.id = searchedCustomer.payment_customer.address.id;
        }

        if (searchedCustomer?.payment_customer?.person?.id) {
            newCustomer.personId = searchedCustomer.payment_customer.person.id;
        }
     
        /*
        let resCustomerId = undefined;
        if (!newCustomer.id) {
            console.log(`CREATE`)
            const result = await CreateCustomerApi(newCustomer);
            console.log(`CREATE RESULT: ${result}`)
            if (result) {
                resCustomerId = result;
            }
        } else {
            console.log(`UPDATE`)
            resCustomerId = await UpdateCustomerApi(newCustomer);
        }
        console.log(`RES CUSTOMER ID: ${resCustomerId}`)
        */
        //if (resCustomerId) {
            Notification.success({
                message: 'Pagador atualizado!',
                duration: 2,
            });

            props.setDataPayerError(false)

            props.data.forEach( item => { item.payerCustomer = {
                id: customerId,
                name: customerName,
                email: email,
                registry_code: document,
                code: document,
                address: {
                    ...newCustomer.address
                }
            }})

            props.setVisible(false);
            props.setReload(true);
            //Ir para prox page
            setOrigin('payment')
            setItems(props.data);
            navigate(`/paymentprocess`, 
                //{ state: { data: items[0]?.id }}
            );
        //} 
        /*
        else {
            Notification.error({
                message: 'Não foi possível atualizar os dados do pagador nesse momento!',
                duration: 3,
            });
        }
        */
    }

    useEffect(() => {
        if (!props.onlyRead)
            searchCustomerByCpf()
    }, [document]);

    useEffect(() => {
        if (!props.onlyRead)
            searchCustomerByCpf(props?.data?.paymentCustomer?.registry_code)
    }, [props?.data?.paymentCustomer?.registry_code])


    return (
        <AntModal
            data-testid="modal-form"
            destroyOnClose={true}
            forceRender
            title={
                <Typography.Title style={style.description}>
                    {props.onlyRead ? 'Dados do Pagador' : 'Informe os dados do pagador do lote'}
                </Typography.Title>
            }
            open={props.visible}
            centered={true}
            onOk={() => props.setVisible(!props.visible)}
            onCancel={() => props.setVisible(!props.visible)}
            footer={[
                <Button
                    text= {props.onlyRead ? 'Voltar' : 'Cancelar'}
                    key="cancelar"  
                    nativeProps={{
                        ghost: true,
                    }}               
                    loading={loading}
                    onClick={() => props.setVisible(false)}
                />                 
                ,
                !props.onlyRead &&  (<Button
                    text="Confirmar"
                    key="salvar"
                    loading={loading}
                    nativeProps={{
                        htmlType: 'submit',
                    }}
                    onClick={async () => await handleUpdatePayer()}
                />),
            ]}
        >
            <Form
                layout="horizontal"
                validateMessages={validateMessages}
                style={{
                    textAlign: 'center',
                }}
            >
                <Row style={{ marginTop: -30 }}>
                    <Col span={5} style={{ padding: 10 }}>
                        <Form.Item
                            label="CPF/CNPJ"
                            labelCol={{ span: 24 }}
                        >
                            <InputMask
                                mask={determineMaskDocument(document)}
                                maskChar=""
                                disabled={props.onlyRead}
                                onChange={handleChangeDocument}
                                style={{
                                    borderRadius: 20,
                                    height: 40,
                                    borderStyle: 'solid',
                                    borderWidth: 1,
                                    paddingLeft: 10,
                                    borderColor: error ? '#ff0000' : '#D9D9D9'
                                }}
                                value={document}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row style={{ marginTop: -30, marginLeft: -10 }}>
                    <Col span={24} style={{ padding: 20 }}>
                        <Form.Item
                            label="Nome"
                            labelCol={{ span: 24 }}
                        >
                            <Input
                                style={{
                                    height: 40,
                                    marginTop: -15,
                                    textTransform: 'uppercase',
                                    borderColor: error ? '#ff0000' : '#D9D9D9'
                                }}
                                placeholder="Informe o Nome"
                                disabled={props.onlyRead}
                                onChange={(e) => setCustomerName(e.target.value)}
                                value={customerName}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row style={{ marginTop: -60, marginLeft: -10 }}>
                    <Col span={24} style={{ padding: 20 }}>
                        <Form.Item
                            label="E-mail"
                            labelCol={{ span: 24 }}
                        >
                            <Input
                                style={{
                                    height: 40,
                                    marginTop: -15,
                                    textTransform: 'lowercase',
                                    borderColor: error ? '#ff0000' : '#D9D9D9'
                                }}
                                placeholder="Informe o e-mail"
                                disabled={props.onlyRead}
                                onChange={(e) => setEmail(e.target.value)}
                                value={email}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Divider style={{ marginTop: -20 }} />

                <Row style={{ marginTop: -30, marginLeft: -10 }}>
                    <Col span={4} style={{ padding: 20 }}>
                        <Form.Item
                            label="CEP"
                            labelCol={{ span: 24 }}
                        >
                            <InputMask
                                mask="99999-999"
                                maskChar="_"
                                disabled={props.onlyRead}
                                onChange={(e) => setCep(e.target.value)}
                                onBlur={async (e) => await handleZipCode(e.target.value)}
                                value={cep}
                                style={{
                                    marginTop: -15,
                                    borderRadius: 20,
                                    height: 40,
                                    borderStyle: 'solid',
                                    borderWidth: 1,
                                    paddingLeft: 10,
                                    borderColor: error ? '#ff0000' : '#D9D9D9'
                                }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row style={{ marginTop: -60, marginLeft: -10 }}>
                    <Col span={17} style={{ padding: 20 }}>
                        <Form.Item
                            label="Rua"
                            labelCol={{ span: 24 }}
                        >
                            <Input
                                style={{
                                    height: 40,
                                    marginTop: -15,
                                    textTransform: 'uppercase',
                                    borderColor: error ? '#ff0000' : '#D9D9D9'
                                }}
                                placeholder="Informe a rua"
                                disabled={props.onlyRead}
                                onChange={(e) => setAddress(e.target.value)}
                                value={address}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={7} style={{ padding: 20 }}>
                        <Form.Item
                            label="Número"
                            labelCol={{ span: 24 }}
                        >
                            <Input
                                style={{
                                    height: 40,
                                    width: 100,
                                    marginTop: -15,
                                    textTransform: 'uppercase',
                                    borderColor: error ? '#ff0000' : '#D9D9D9'
                                }}
                                placeholder="Número"
                                disabled={props.onlyRead}
                                onChange={(e) => setNumberHome(e.target.value)}
                                value={numberHome}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row style={{ marginTop: -60, marginLeft: -10 }}>
                    <Col span={24} style={{ padding: 20 }}>
                        <Form.Item
                            label="Bairro"
                            labelCol={{ span: 24 }}
                        >
                            <Input
                                style={{
                                    height: 40,
                                    textTransform: 'uppercase',
                                    marginTop: -15
                                }}
                                placeholder="Informe o bairro"
                                disabled={props.onlyRead}
                                onChange={(e) => setNeighborhood(e.target.value)}
                                value={neighborhood}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row style={{ marginTop: -60, marginLeft: -10 }}>
                    <Col span={12} style={{ padding: 20 }}>
                        <Form.Item
                            label="Cidade"
                            labelCol={{ span: 24 }}
                        >
                            <Input
                                style={{
                                    height: 40,
                                    textTransform: 'uppercase',
                                    marginTop: -15
                                }}
                                placeholder="Informe a cidade"
                                disabled={props.onlyRead}
                                onChange={(e) => setCity(e.target.value)}
                                value={city}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12} style={{ padding: 20 }}>
                        <Form.Item
                            label="Estado"
                            labelCol={{ span: 24 }}
                        >
                            <Input
                                style={{
                                    height: 40,
                                    textTransform: 'uppercase',
                                    marginTop: -15
                                }}
                                placeholder="Informe o estado"
                                disabled={props.onlyRead}
                                onChange={(e) => setState(e.target.value)}
                                value={state}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row style={{ marginTop: -60, marginLeft: -10 }}>
                    <Col span={24} style={{ padding: 20 }}>
                        <Form.Item
                            label="Complemento"
                            labelCol={{ span: 24 }}
                        >
                            <Input
                                style={{
                                    height: 40,
                                    textTransform: 'uppercase',
                                    marginTop: -15
                                }}
                                placeholder="Informe o complemento"
                                disabled={props.onlyRead}
                                onChange={(e) => setComplement(e.target.value)}
                                value={complement}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </AntModal>
    );
};

export { Payer };
