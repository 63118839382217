import { AxiosInstance } from "axios";
import { IChartNextDays, ISimpleChart } from "../domain/dashboard.entities";
import { DashboardGateway } from "../domain/dashboard.gateway";
import { Paths } from './keys';

export class DashboardHttpGateway implements DashboardGateway {
    constructor(private http: AxiosInstance) { }

    async getSchedulesNext30Days(): Promise<IChartNextDays[]> {
        return await this.http.get<IChartNextDays[]>(Paths.scheduleNext30Days).then((response) => response.data);
        }

    async getSchedulesToday(): Promise<ISimpleChart> {
        // return await this.http.get<ISimpleChart>(Paths.scheduleToday).then((response) => response.data);

        const today = new Date().toISOString().slice(0, 10)

        const path = `/api/customer-portal/schedules?offset=0&limit=1&dtIni=${today}&dtFim=${today}&status=pending%2CinProgress%2Cconfirmed%2Cfinished%2Crescheduled`
        return await this.http.get<any>(path).then((response) => {
            return {total: Number(response.data.pagination.total)};
        });
    }

    async getSchedulesYesterday(): Promise<ISimpleChart> {
        // return await this.http.get<ISimpleChart>(Paths.scheduleYesterday).then((response) => response.data);

        const yesterday = new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().slice(0, 10)

        const path = `/api/customer-portal/schedules?offset=0&limit=1&dtIni=${yesterday}&dtFim=${yesterday}&status=finished`
        return await this.http.get<any>(path).then((response) => {
            return {total: Number(response.data.pagination.total)};
        });
    }

    async pendingToConfirmation(): Promise<ISimpleChart> {
        // return await this.http.get<ISimpleChart>(Paths.schedulePendingToConfirmation).then((response) => response.data);

        const initial = new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().slice(0, 10);
        const nextYear = new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toISOString().slice(0, 10)

        const path = `/api/customer-portal/schedules?offset=0&limit=1&dtIni=${initial}&dtFim=${nextYear}&status=pending`
        return await this.http.get<any>(path).then((response) => {
            return {total: Number(response.data.pagination.total)};
        });
    }

    async pendingToPay(): Promise<ISimpleChart> {
        // return await this.http.get<ISimpleChart>(Paths.schedulePendingToPay).then((response) => response.data);

        const initial = new Date(new Date().setDate(new Date().getDate() - 6)).toISOString().slice(0, 10)
        const end = new Date(new Date()).toISOString().slice(0, 10)

        const path = `/api/customer-portal/schedules?offset=0&limit=1&dtIni=${initial}&dtFim=${end}&status=pending%2CinProgress%2Cconfirmed%2Cfinished%2Crescheduled&statusBilling=pendingToPay%2Callpending&ignoreCanceled=true`
        return await this.http.get<any>(path).then((response) => {
            console.log(response.data);
            return {total: Number(response.data.pagination.total)};
        });
    }
}