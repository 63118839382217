import { Row, Col } from 'antd';
import { Card } from "../../components/Card";
import { useNavigate } from 'react-router-dom';
import { useSelectedMenuContext } from '@/@presentation/contexts/selectedMenu';
import apiDashboard from '@/@core/modules/dashboard/infra/container.registry';
import { ISimpleChart, IChartNextDays } from '@/@core/modules/dashboard/domain/dashboard.entities';
import { useItemsContext } from '@/@presentation/contexts/schedulesContext'
import React from 'react';
import { CardWithChart } from '../../components/CardWithChart';

const Home = () => {
    const [schedulesToday, setSchedulesToday] = React.useState<ISimpleChart>({total: 0});
    const [schedulesYesterday, setSchedulesYesterday] = React.useState<ISimpleChart>({total: 0});
    const [pendingToConfirmation, setPendingToConfirmation] = React.useState<ISimpleChart>({total: 0});
    const [pendingToPay, setPendingToPay] = React.useState<ISimpleChart>({total: 0});
    const [schedulesNext30Days, setSchedulesNext30Days] = React.useState<IChartNextDays[]>([]);
    const [loadingCharts, setLoadingCharts] = React.useState<boolean>(true);

    const loadCharts = async () => {
        setLoadingCharts(true);

        const today = await apiDashboard.get.getSchedulesToday();
        const yesterday = await apiDashboard.get.getSchedulesYesterday();
        const pendingToConfirmation = await apiDashboard.get.getSchedulesPendingToConfirmation();
        const pendingToPay = await apiDashboard.get.getSchedulesPendingToPay();
        const next30Days = await apiDashboard.get.getSchedulesNext30Days();

        setSchedulesToday(today);
        setSchedulesYesterday(yesterday);
        setPendingToConfirmation(pendingToConfirmation);
        setPendingToPay(pendingToPay);
        setSchedulesNext30Days(next30Days);

        setLoadingCharts(false);
    }

    const navigate = useNavigate();
    const { setSelectedMenu } = useSelectedMenuContext();

    const {
        setIsRedirect,
        setInitialDate,
        setFinalDate,
        setSurgeon,
        setStatusFilter,
        setLocale,
        setScheduleId,
        setRequester,
        setPatient,
        setCreatedInitial,
        setCreatedFinal,
        setStatusBilling,
    } = useItemsContext()

    const goTo = (page: '/schedule' | '/finance', type?: 'PROCEDURE_TODAY'| 'PROCEDRE_PENDING_TO_PAY' | 'PROCEDURE_TO_COFIRMSTION' | 'PROCEDURE_FINISH_YESTERDAY' | 'PROCEDURE_NEXT_30_DAYS' ) => {
        setSelectedMenu(page);

        if (type === 'PROCEDURE_TODAY') {
            const today = new Date().toISOString().slice(0, 10)
            setStatusFilter(['pending', 'inProgress','confirmed', 'finished', 'rescheduled']);
            setIsRedirect(true);
            setInitialDate(today);
            setFinalDate(today);

            setSurgeon(undefined);
            setLocale(undefined);
            setScheduleId(undefined);
            setRequester(undefined);
            setPatient(undefined);
            setCreatedInitial(undefined);
            setCreatedFinal(undefined);
            setStatusBilling(undefined);
        }

        if (type === 'PROCEDRE_PENDING_TO_PAY') {
            const initial = new Date(new Date().setDate(new Date().getDate() - 6)).toISOString().slice(0, 10)
            const end = new Date(new Date()).toISOString().slice(0, 10)
            setIsRedirect(true);
            setInitialDate(initial);
            setFinalDate(end);
            setStatusBilling(['pendingToPay', 'allpending']);
            setStatusFilter(['pending', 'inProgress','confirmed', 'finished', 'rescheduled']);
            setSurgeon(undefined);
            setLocale(undefined);
            setScheduleId(undefined);
            setRequester(undefined);
            setPatient(undefined);
            setCreatedInitial(undefined);
            setCreatedFinal(undefined);
        }

        if (type === 'PROCEDURE_TO_COFIRMSTION') {
            const initial = new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().slice(0, 10);
            const nextYear = new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toISOString().slice(0, 10)
            setIsRedirect(true);
            setInitialDate(initial);
            setFinalDate(nextYear);
            setStatusFilter('pending');

            setSurgeon(undefined);
            setLocale(undefined);
            setScheduleId(undefined);
            setRequester(undefined);
            setPatient(undefined);
            setCreatedInitial(undefined);
            setCreatedFinal(undefined);
            setStatusBilling(undefined);
        }

        if (type === 'PROCEDURE_FINISH_YESTERDAY') {
            const yesterday = new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().slice(0, 10)
            setIsRedirect(true);
            setInitialDate(yesterday);
            setFinalDate(yesterday);
            setStatusFilter('finished');

            setSurgeon(undefined);
            setLocale(undefined);
            setScheduleId(undefined);
            setRequester(undefined);
            setPatient(undefined);
            setCreatedInitial(undefined);
            setCreatedFinal(undefined);
            setStatusBilling(undefined);
        }

        if (type === 'PROCEDURE_NEXT_30_DAYS') {
            const today = new Date().toISOString().slice(0, 10)
            const nextMonth = new Date()
            nextMonth.setDate(nextMonth.getDate() + 30)
            const nextMonthDate = nextMonth.toISOString().slice(0, 10)

            setInitialDate(today);
            setFinalDate(nextMonthDate);
            
            setIsRedirect(true);
            setStatusFilter(['pending', 'inProgress','confirmed', 'finished', 'rescheduled']);

            setSurgeon(undefined);
            setLocale(undefined);
            setScheduleId(undefined);
            setRequester(undefined);
            setPatient(undefined);
            setCreatedInitial(undefined);
            setCreatedFinal(undefined);
            setStatusBilling(undefined);
        }

        navigate(page);
    }

    React.useEffect(() => {
        loadCharts();
    }, []);

    return (
        <div style={{ width: '100%' }}>
            <Row gutter={16} style={{ width: '100%' }}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Card title="Quantidade de agendamentos para hoje" value={schedulesToday.total} onClick={() => goTo('/schedule', 'PROCEDURE_TODAY')} />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Card title="Procedimentos pendentes de pagamento" value={pendingToPay.total} onClick={() => goTo('/finance', 'PROCEDRE_PENDING_TO_PAY')} />
                </Col>
            </Row>
            <Row gutter={16} style={{ width: '100%' }}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Card title="Agendamentos pendentes de confirmação" value={pendingToConfirmation.total} onClick={() => goTo('/schedule', 'PROCEDURE_TO_COFIRMSTION')} />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Card title="Procedimentos concluídos na data de ontem" value={schedulesYesterday.total} onClick={() => goTo('/schedule', 'PROCEDURE_FINISH_YESTERDAY')} />
                </Col>
            </Row>
            <Row gutter={16}>
                <CardWithChart onClick={() => goTo('/schedule', 'PROCEDURE_NEXT_30_DAYS')} title="Agendamentos para os próximos 30 dias" data={schedulesNext30Days.filter(item => item.total > 0)} />
            </Row>
        </div>
    )
}

export default Home;