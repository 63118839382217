import React from 'react';
import { Col, Form, Layout, Row, Table } from 'antd';
import {
    Button,
    List
} from '@presentation/components/form';
import { columns } from './columns';
import ConcilitorFilters from './components/filters';
import ConciliatorReadValues from './components/readValues';
import { ConciliationTotal, ConciliatorFilter, IConciliator } from '@/@core/modules/conciliator/domain/conciliator.entity';
import conciliator from '@/@core/modules/conciliator/infra/container.registry';
import { useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

interface IParams {
    paymentType: 'CREDIT_CARD' | 'BANK_SLIP_AND_PIX',
    initialTransactionDate: string,
    finalTransactionDate: string,
    adquirente: string,
    companyName: string,
    bank: string,
}

const ConcilitorList = () => {
    const [loading, setLoading] = React.useState(false);
    const [loadingProcess, setLoadingProcess] = React.useState(false);
    const [modalVisible, setModalVisible] = React.useState(false);
    const [params, setParams] = React.useState<IParams | null>(null);

    const [reload, setReload] = React.useState(false);

    const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);
    const [selectedItem, setSelectedItem] = React.useState<IConciliator[] | []>([]);

    const [totals, setTotals] = React.useState<ConciliationTotal>({} as ConciliationTotal);
    const [data, setData] = React.useState<IConciliator[]>([]);

    const [selectedPaymentType, setSelectedPaymentType] = React.useState<"CREDIT_CARD" | "BANK_SLIP_AND_PIX" | "SELECT">('SELECT');

    const [form] = Form.useForm();

    const navigate = useNavigate();

    const exportToExcel = (data: any, fileName: string) => {
        const worksheet = XLSX.utils.json_to_sheet(data);

        const workbook = XLSX.utils.book_new();

        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

        const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
        saveAs(blob, `${fileName}.xlsx`);
      };

      const handleExport = async () => {
        setLoading(true);
        exportToExcel(data, `conciliacao-takaoka-portal-${new Date().toISOString()}`);
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }

    const handleProcess = async () => {
        setLoadingProcess(true);
        try {
            await conciliator.process.execute(selectedItem!.map(item => item.idConciliation));
            setSelectedItem([]);
            setSelectedRowKeys([]);
            loadData(params);
        } catch {
            // Tratamento de erro
        } finally {
            setLoadingProcess(false);
        }
    }
    async function loadData(params: ConciliatorFilter | any) {
        setLoading(true);
        try {
            const result = await conciliator.getList.execute(params);
            setTotals(result.totals);
            setData(result.data);
        } catch {
            // Tratamento de erro
        } finally {
            setLoading(false);
        }
    }

    const showList = (selectedPaymentType: 'SELECT' | 'CREDIT_CARD' | 'BANK_SLIP_AND_PIX') => {
        const initialDate = form.getFieldValue('initialTransactionDate');
        const finalDate = form.getFieldValue('finalTransactionDate');

        if (selectedPaymentType === 'SELECT') {
            return false;
        }

        if (selectedPaymentType === 'CREDIT_CARD' && (!initialDate || !finalDate)) {
            return false;
        }

        if (selectedPaymentType === 'BANK_SLIP_AND_PIX' && !initialDate) {
            return false;
        }

        return true;
    }

    const handleLoadData = async () => {
        const values = form.getFieldsValue();

        if (!showList(selectedPaymentType)) {
            return;
        }

        const newParams: IParams = {
            paymentType: selectedPaymentType,
            initialTransactionDate: values.initialTransactionDate,
            finalTransactionDate: values.finalTransactionDate,
            adquirente: values.adquirente || values.bank,
            companyName: values.companyName,
            bank: values.bank,
        };

        setParams(newParams);
        await loadData(newParams);
    }

    React.useEffect(() => {
        form.resetFields();
        setData([]);
        setTotals({} as ConciliationTotal);
        setSelectedItem([]);
        setSelectedRowKeys([]);

        if (selectedPaymentType !== 'SELECT') {
            handleLoadData();
        }
    }, [selectedPaymentType]);

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: IConciliator[]) => {
            setSelectedItem(selectedRows);
            setSelectedRowKeys(selectedRowKeys);
        },
        getCheckboxProps: (record: IConciliator) => ({
            disabled: record.status === 'Localizado' || record.status === 'aguardando_checkout',
        }),
    };

    return (
        <Layout data-testid="layout-administration">
            <ConcilitorFilters selectedPaymentType={selectedPaymentType} setSelectedPaymentType={setSelectedPaymentType} form={form} />
            {selectedPaymentType !== 'SELECT' && showList(selectedPaymentType) && <ConciliatorReadValues selectedPaymentType={selectedPaymentType} total={totals} />}
            {
                showList(selectedPaymentType) && (
                    <Row style={{ marginBottom: 20, borderRadius: 8 }}>
                        <Col span={24}>
                            <Button
                                type="primary"
                                loading={loading}
                                onClick={() => handleExport()}
                                nativeProps={{
                                    style: { margin: '20px 0' }
                                }}
                                text='Exportar'
                            />
                            <List
                                loadData={loadData}
                                columns={columns(navigate)}
                                setLoading={setLoading}
                                loading={loading}
                                setModalVisible={setModalVisible}
                                modalVisible={modalVisible}
                                setParams={setParams}
                                params={params}
                                isReload={reload}
                                setReload={setReload}
                                showStatusBillingFilter={false}
                                showStatusFilter={false}
                                hideDataFilter={true}
                                rowKey='idConciliation'
                                showPagination={false}
                                rowSelection={rowSelection}
                                data={data}
                            />
                        </Col>
                    </Row>
                )
            }

            <Col span={24} style={{ textAlign: 'right', marginBottom: '20px' }}>
                <Row gutter={16} justify="end">
                    <Button
                        type="primary"
                        disabled={selectedItem.length === 0}
                        onClick={() => handleProcess()}
                        loading={loadingProcess}
                        nativeProps={{
                            style: { margin: '20px 15px 20px 0' }
                        }}
                        text='Processar'
                    />
                    <Button
                        type="primary"
                        onClick={() => handleLoadData()}
                        loading={loading}
                        nativeProps={{
                            style: { margin: '20px 0' }
                        }}
                        text='Atualizar Data'
                    />
                </Row>
            </Col>
        </Layout>
    );
};

export default ConcilitorList;
