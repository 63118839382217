import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Col, Form, Layout, Row, Space, Spin, Input, Select,  Divider} from 'antd';
import { Content } from 'antd/es/layout/layout';
import { LoadingOutlined } from '@ant-design/icons';
import { useItemsContext } from '@/@presentation/contexts/schedulesContext';
import moment from 'moment';
import CheckCep from '@/services/viaCep';
import { Notification } from '@presentation/components/common';
import { UpdateCustomerInterface } from '../home/receiver';
import customer from '@/@core/modules/customer/infra/container.registry';
import { BillInterface } from '@/@core/modules/bill/domain/bill.entities';
import bill from '@/@core/modules/bill/infra/container.registry';
import cataGadp from '@/@core/modules/validateCataGadp/infra/container.registry';
import { TProcedureUpdate } from '@/@core/modules/schedule/domain/schedule.entities';
import schedule from '@/@core/modules/schedule/infra/container.registry';
import { SearchCustomerInterface } from '@/@core/modules/customer/domain/customer.entity';
import { Button } from '@/@presentation/components/form';
import { Payer } from '../home/payer';
import scheduuleRegistry from '@/@core/modules/schedule/infra/container.registry';
import InputMask from 'react-input-mask';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

interface paymentOptionsInterface {
    label: string,
    value: string
}

const PaymentProcess = () => {
    //const location = useLocation();
    const navigate = useNavigate();
    const location = useLocation();
    const { items, setItems } = useItemsContext();

    const [loading, setLoading] = React.useState(false);

    const [isDisabled, setIsDisabled] = useState(false);

    const [pagador, setPagador] = useState('')
    const [dtCirurgia, setDtCirurgia] = useState<string>('')
    const [paymentType, setPaymentType] = useState('')
    const [intallentNumber, setIntallentNumber] = useState(1)
    const [document, setDocument] = useState('')
    const [email, setEmail] = useState('')
    const [cep, setCep] = useState('')
    const [address, setAddress] = useState('')
    const [numberHome, setNumberHome] = useState('')
    const [complement, setComplement] = useState('')
    const [neighborhood, setNeighborhood] = useState('')
    const [city, setCity] = useState('')
    const [state, setState] = useState('')
    const [payerCustomerId, setPayerCustomerId] = useState(0)
    const [customerData, setCustomerData] = useState<SearchCustomerInterface>();

    const [formattedTotalValue, setFormattedTotalValue] = useState('');
    const [formattedTotalValueToPay, setFormattedTotalValueToPay] = useState('');

    const [error, setError] = useState(false)
    const [showModalPayer, setShowModalPayer] = useState(false);
    const [isCNPJ, setIsCNPJ] = useState(false);

    const [paymentOptions, setPaymentOptions] = useState<paymentOptionsInterface[]>([])
    const [installmentsNumber, setInstallmensNumber] = useState<paymentOptionsInterface[]>([])

    const [dataPayerError, setDataPayerError] = useState(false);

    const handleChangeDocument = (e: any) => {
        const { value } = e.target;
        setDocument(value?.replace(/\D/g, ''));
        setError(false);
    };

    const determineMaskDocument = (str: string = '') => {
        const checkValue = str ?? '';
        const value = checkValue?.replace(/\D/g, '');
        return value.length > 11 ? '99.999.999/9999-99' : '999.999.999-999';
    };

    async function validateCNPJ(strDocument: string): Promise<boolean> {
        strDocument = strDocument?.replace(/\D/g, '');
        if (strDocument === '00000000000000' || strDocument === '11111111111111' || strDocument === '22222222222222' || strDocument === '33333333333333' ||
            strDocument === '44444444444444' || strDocument === '55555555555555' || strDocument === '66666666666666' || strDocument === '77777777777777' ||
            strDocument === '88888888888888' || strDocument === '99999999999999' || strDocument.length !== 14) {
            return false;
        }

        let tamanho = strDocument.length - 2;
        let numeros = strDocument.substring(0, tamanho);
        let digitos = strDocument.substring(tamanho);
        let soma = 0;
        let pos = tamanho - 7;
        for (let i = tamanho; i >= 1; i--) {
            soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
            if (pos < 2) {
                pos = 9;
            }
        }
        let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
        if (resultado !== parseInt(digitos.charAt(0))) {
            return false;
        }

        tamanho = tamanho + 1;
        numeros = strDocument.substring(0, tamanho);
        soma = 0;
        pos = tamanho - 7;
        for (let i = tamanho; i >= 1; i--) {
            soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
            if (pos < 2) {
                pos = 9;
            }
        }
        resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
        if (resultado !== parseInt(digitos.charAt(1))) {
            return false;
        }

        return true;
    }

    async function validateCPF(strDocument: string): Promise<boolean> {
        strDocument = strDocument?.replace(/\D/g, '');
        if (strDocument === '00000000000' || strDocument === '11111111111' || strDocument === '22222222222' || strDocument === '33333333333' ||
            strDocument === '44444444444' || strDocument === '55555555555' || strDocument === '66666666666' || strDocument === '77777777777' ||
            strDocument === '88888888888' || strDocument === '99999999999' || strDocument.length !== 11) {
            return false;
        }

        let Soma: number = 0;
        for (let i = 1; i <= 9; i++) {
            Soma = Soma + parseInt(strDocument.substring(i - 1, i)) * (11 - i);
        }

        let Resto = (Soma * 10) % 11;
        if (Resto === 10 || Resto === 11) {
            Resto = 0;
        }

        if (Resto !== parseInt(strDocument.substring(9, 10))) {
            return false;
        }

        Soma = 0;
        for (let k = 1; k <= 10; k++) {
            Soma = Soma + parseInt(strDocument.substring(k - 1, k)) * (12 - k);
        }

        Resto = (Soma * 10) % 11;
        if (Resto === 10 || Resto === 11) {
            Resto = 0;
        }

        if (Resto !== parseInt(strDocument.substring(10, 11))) {
            return false;
        }

        return true;
    }

    const formatMissingFields = (missingFieldsPayer: string[]): string => {
        if (missingFieldsPayer.length === 0) return '';
    
        if (missingFieldsPayer.length === 1) return missingFieldsPayer[0];
    
        const lastField = missingFieldsPayer.pop();
        return `${missingFieldsPayer.join(', ')} e ${lastField}`;
    };

    async function handlePayment() {
        setLoading(true)
        const strDocument = document?.replace(/\D/g, '');
        const validadeCpfOuCnpj = strDocument.length > 11 ? await validateCNPJ(strDocument) : await validateCPF(strDocument)
        
        if (validadeCpfOuCnpj === false) {
            Notification.error({
                message: 'O CPF/CNPJ informado não é valido!',
                duration: 3,
            });
            setLoading(false)
            return
        }
        if (dataPayerError) {
            Notification.error({
                message: `Por favor, preencha os campos obrigatórios do pagador!`,
                duration: 5,
            });
            setLoading(false)
            return;

        }
        if (paymentType.length === 0) {
            setError(true)
            Notification.error({
                message: 'Por favor informar a forma de pagamento!',
                duration: 5,
            });
            setLoading(false)
            return
        } else {
            let payerId = payerCustomerId
            // Verificar CATA ou GADP
            const resVerify = await cataGadp.verify.execute(items[0].id)
            if (payerCustomerId === 0 || document !== items[0]?.cpf) {
                const addressRes = await CheckCep(cep)
                const newCustomer: UpdateCustomerInterface = {
                    scheduleId: items.map(i => i.id),
                    isPayer: true, //Para os próximos casos, essa tela sempre irá atualizar o pagador, tomador ficará para questões de NF
                    name: pagador,
                    email: email,
                    registry_code: document,
                    code: document,
                    address: {
                        street: address,
                        number: numberHome,
                        additional_details: complement,
                        zipcode: cep,
                        neighborhood: addressRes?.bairro || neighborhood,
                        city: addressRes?.localidade || city,
                        state: addressRes?.uf || state,
                    }
                }

                if (customerData?.payment_customer?.id) {
                    newCustomer.id = customerData.payment_customer.id;
                }
                if (customerData?.payment_customer?.address?.id) {
                    newCustomer.address.id = customerData.payment_customer.address.id;
                }
                if (customerData?.payment_customer?.person?.id) {
                    newCustomer.personId = customerData.payment_customer.person.id;
                }

                payerId = await customer.create.execute(newCustomer)
                setPayerCustomerId(payerId)
            }
            
            
            let advancePaymentStatus = false    
            const checkin = items[0].checkIn ?? null
            const checkout = items[0].checkOut ?? null

            if (checkin === null && checkout === null) {
                advancePaymentStatus = true
            }
                   
            for (const item of items) {
                const dataUpdate: TProcedureUpdate = {
                    id: item?.id,
                    amount: Number(formattedSingleValue(item.total)?.replace(/[^\d,]/g, '')?.replace(',', '.')),
                    updatedBySecretary: false
                };

                if (isNaN(dataUpdate.amount)){
                    Notification.error({
                        message: 'Falha ao converter valor dos procedimento',
                        duration: 3,
                    });
                    setLoading(false)
                    return
                }
                if (isCNPJ) {
                    dataUpdate.netValue = Number(calcTaxSingleValue(item.total)?.replace(/[^\d,]/g, '')?.replace(',', '.'));
                }

                if(advancePaymentStatus){
                    dataUpdate.paymentCustomerId = payerId
                }
                await schedule.update.execute(dataUpdate);
            }

            const newBill: BillInterface = {
                paymente_customer_id: payerId,
                installments: intallentNumber,
                method_code: paymentType,
                schedules_ids: items?.map((item) => item?.id) || [],
                advance_payment: advancePaymentStatus,
                payment_billing_id: items[0]?.paymentBillingId ?? 0,
                typeCG: resVerify === 3 ? 'cata' : 'gadp'
            }

            const res = await bill.create.execute(newBill)

            if (res) {

                Notification.success({
                    message: 'Pagamento gerado e encaminhado com sucesso!',
                    duration: 2,
                });

                setLoading(false)

                navigate('/finance')

            } else {

                Notification.error({
                    message: 'Não foi possível solicitar o pagamento nesse momento! Tente Novamente!',
                    duration: 2,
                });

                setLoading(false)
            }
            setError(false)
        }

    }

    useEffect(() => {
        setDtCirurgia(items[0]?.procedureDate ? moment(items[0]?.procedureDate, 'DD/MM/YYYY').format('YYYY-MM-DD') : '');

        if (items[0]?.installments_number === 0 || items[0]?.installments_number === undefined) {

            setPaymentOptions([
                { label: 'BOLETO', value: 'bank_slip' },
                { label: 'PIX', value: 'pix' }
            ])

        } else {

            setPaymentOptions([
                { label: 'BOLETO', value: 'bank_slip' },
                { label: 'PIX', value: 'pix' },
                { label: 'CARTÃO DE CRÉDITO', value: 'credit_card' }
            ])

            const newInstallments = [];
            for (let i = 1; i <= items[0]?.installments_number; i++) {
                newInstallments.push({ label: `${i}`, value: `${i}` });
            }

            setInstallmensNumber(newInstallments)
        }

        setPagador(items[0].payerCustomer?.name || 'Não informado')
        setEmail(items[0]?.payerCustomer?.email ?? '')
        setDocument(items[0]?.payerCustomer?.registry_code ?? '')

        setPayerCustomerId(items[0]?.payerCustomer?.id || 0)
        if (items[0].payerCustomer?.address) {

            setCep(items[0]?.payerCustomer?.address?.zipcode ?? '')
            setAddress(items[0]?.payerCustomer?.address?.street ?? '')
            setNumberHome(items[0]?.payerCustomer?.address?.number ?? '')

        } else {

            setCep(items[0]?.paymentCustomer?.zipcode ?? '')
            setAddress(items[0]?.paymentCustomer?.street ?? '')
            setNumberHome(items[0]?.paymentCustomer?.number ?? '')
        }

        setPaymentType(items[0]?.methodCode ?? '')
        setIntallentNumber(items[0]?.paymentInstallmentsNumber ?? 1)

    }, [items])


    useEffect(() => {
        setDocument(items[0]?.payerCustomer?.code || '')
        setPagador(items[0]?.payerCustomer?.name || 'Não informado')
        setEmail(items[0]?.payerCustomer?.email ?? '')
        setDocument(items[0]?.payerCustomer?.registry_code ?? '')

        setPayerCustomerId(items[0]?.payerCustomer?.id || 0)
        if (items[0].payerCustomer?.address) {

            setCep(items[0]?.payerCustomer?.address?.zipcode ?? '')
            setAddress(items[0]?.payerCustomer?.address?.street ?? '')
            setNumberHome(items[0]?.payerCustomer?.address?.number ?? '')

        } else {

            setCep(items[0]?.paymentCustomer?.zipcode ?? '')
            setAddress(items[0]?.paymentCustomer?.street ?? '')
            setNumberHome(items[0]?.paymentCustomer?.number ?? '')
        }
        //Para validar se aplicaremos a taxa ou não de PJ, precisamos validar o TOMADOR
        const formatedDocument = items[0].paymentCustomer?.registry_code?.replace(/\D/g, '')//payerCustomer?.code.replace(/\D/g, '')
        setIsCNPJ(formatedDocument?.length > 11)
    }, [showModalPayer])

    async function handleZipCode(zipcode: string) {

        if (zipcode?.length > 7) {

            const address = await CheckCep(zipcode)
            setAddress(address.logradouro)
            setNeighborhood(address.bairro)
            setCity(address.localidade)
            setState(address.uf)
        }

    }

    const totalValue = () => {
        const total = items.reduce((acc, item) => {
            if (!item.total) {
                return acc;
            }
            const totalWithoutCurrency = item.total?.replace(/[^\d,]/g, '');
            const totalWithDot = totalWithoutCurrency?.replace(',', '.');
    
            const parsedTotal = parseFloat(totalWithDot);
    
            return isNaN(parsedTotal) ? acc : acc + parsedTotal;
        }, 0);
    
        const formattedTotal = total.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
    
        setFormattedTotalValue(formattedTotal);
    
        return total;
    };

   
    
    const taxDeduction = (grossValue: number) => {
        let tax1 = Math.round(grossValue * 0.0465 * 100) / 100;
        let tax2 = Math.round(grossValue * 0.0150 * 100) / 100;
        
        tax1 = tax1 < 10 ? 0 : tax1;
        tax2 = tax2 < 10 ? 0 : tax2;

        const totalTax = tax1 + tax2;
        const netValue = Math.round((grossValue - totalTax) * 100) / 100;

        return netValue;
    }    
    
    const totalValueToPay = () => {
        const total = items.reduce((acc, item) => {
            if (!item.total) {
                return acc;
            }
            
            const totalWithoutCurrency = String(item.total)?.replace(/[^\d,]/g, '');
            const totalWithDot = totalWithoutCurrency?.replace(',', '.');
            
            const parsedTotal = parseFloat(totalWithDot);
            const value = isNaN(parsedTotal) ? 0 : parsedTotal;
            return acc + taxDeduction(value);
        }, 0);
    
        const formattedTotal = total.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
    
        setFormattedTotalValueToPay(formattedTotal);
    };
    
    function formattedSingleValue(value){
        try {
            const totalWithoutCurrency = String(value)?.replace(/[^\d,]/g, '');
            const totalWithDot = totalWithoutCurrency?.replace(',', '.');

            const parsedTotal = parseFloat(totalWithDot);
            if (isNaN(parsedTotal))
                return totalWithDot

            const formated = parsedTotal?.toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });
            return formated
        } catch (error) {
            console.log(`formattedSingleValue error: ${error}`)
            return value
        }      
    }

    function calcTaxSingleValue(value){
        try {
            
            const totalWithoutCurrency = String(value)?.replace(/[^\d,]/g, '');
            const totalWithDot = totalWithoutCurrency?.replace(',', '.');    
            const parsedTotal = parseFloat(totalWithDot);
            console.log('calcTaxSingleValue',parsedTotal)
            const val = isNaN(parsedTotal) ? 0 : parsedTotal;
            const value_tax = taxDeduction(val);
            
        
            const formattedValue = value_tax.toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });
        
            return formattedValue;
        } catch (error) {
            console.log(`calcTaxSingleValue error: ${error}`)
            return value
        }      
    }

    const setCustumerByCpf = async (cpf: string) => {
        const result: SearchCustomerInterface = await customer.search.execute(cpf)
        setCustomerData(result)
        totalValueToPay();

        setPagador(result?.payment_customer?.person.name ?? '')
        setEmail(result?.payment_customer?.person.email ?? '')
        setCep(result?.payment_customer?.address.zipcode ?? '')
        setAddress(result?.payment_customer?.address.street ?? '')
        setNumberHome(result?.payment_customer?.address.number ?? '')
        setComplement(result?.payment_customer?.address.additional_details ?? '')
        setNeighborhood(result?.payment_customer?.address.neighborhood ?? '')
        setCity(result?.payment_customer?.address.city ?? '')
        setState(result?.payment_customer?.address.state ?? '')
        setPayerCustomerId(result?.payment_customer?.id)
    }

    const searchCustomerByCpf = async (initialCpf?: string) => {
        if (!initialCpf && !document) {
            return;
        }

        const formatCpf = document?.replace(/\D/g, '')
        const formatInitialCpf = initialCpf?.replace(/\D/g, '')

        if (formatInitialCpf && (formatInitialCpf?.length === 11 || formatInitialCpf?.length === 14)) {
            await setCustumerByCpf(formatInitialCpf)
            //setIsCNPJ(formatInitialCpf?.length > 11)
            return;
        }

        await customer.search.execute(formatCpf)

        totalValueToPay();
    }

    useEffect(() => {
        if (items[0]?.payerCustomer?.registry_code) {
            searchCustomerByCpf(items[0]?.payerCustomer?.registry_code)
            return;
        }

        if (location.state?.registry_code) {
            searchCustomerByCpf(location.state?.registry_code)
            setDocument(location.state?.registry_code)
            return;
        }
    }, [])
    
    // const handleInit = async () => {
    //     // const scheduleSelected = await scheduuleRegistry.get.execute({id: location.state?.id})
    //     // searchCustomerByCpf(scheduleSelected?.props?.paymentCustomer?.registry_code)
    //     // setDocument(scheduleSelected?.props?.paymentCustomer?.registry_code ?? '')
    // }

    // useEffect(() => {
    //     handleInit();
    // }, [])

    useEffect(() => {
        const equal = items[0]?.payerCustomer?.registry_code === document
        if (equal) {
            return;
        }
        console.log('useEffectDocument')
        if (document?.replace(/\D/g, '').length === 11 || document?.replace(/\D/g, '').length === 14) { 
            searchCustomerByCpf(document)
        }
    }, [document])

    useEffect(() => {
        console.log('items', items)
        totalValue();
        const isDisabled = items.some(item => 
            ((item?.checkIn && 
            item?.checkOut) &&
            (item?.statusInvoice === 'Emitido' || item?.statusInvoice === 'Solicitado')) ||
            (item?.additionalValidation || item?.methodCode?.length > 0) // Nova validação
        );
        //Em lote, se tiver 1 methodCode entre eles, setar para esse valor, já que essa nova validação bloqueia os campos?
        const methods = items.filter(item => item?.methodCode?.length > 0)
        console.log('methods', methods)
        if(methods && methods.length > 0)
            setPaymentType(methods[0].methodCode)
        setIsDisabled(isDisabled);
    }, [items])

    const getDetalhes = () => {
        let result = '';
        const sortedItems = items.sort((a, b) => a.id - b.id);
        result+= items?.length > 1 ? `Serviços de Anestesia prestados aos pacientes:\n\n` : `Serviço de Anestesia prestado ao paciente:\n\n`;
        sortedItems.forEach(item => {
            result+= `${item.patient} - Data ${dtCirurgia ? moment(dtCirurgia).format('DD/MM/YYYY') : ''}\n`
        });
        result+= isCNPJ ? `\nValor Bruto - ${formattedTotalValue}\nValor Líquido - ${formattedTotalValueToPay}\n\n` : `\nValor Total - ${formattedTotalValue}\n\n`
        // if(isCNPJ)
        //     result+= `[1]Valor aproximado dos tributos 16,70%\nConforme Lei nº 12741 de 08.12.2012\n\n`
        // result+= 'Anestesista(s)\n'
        // sortedItems.forEach(item => {
        //     result+= `${item.anesthetist}\nCRM: ${item.anesthetistCrm}\n`
  
        // });
        if (result?.length > 2)
            result = result.slice(0, -2);
        return result;
    }    

    return (
        <Layout
            data-testid="layout-schedule-view"
            style={{
                backgroundColor: 'white',
                minWidth: '100%',
                maxWidth: '100%',
                overflow: 'hidden',
            }}
        >
            <Content className="site-layout-content">
                <Content className="pd-24">
                    <Spin spinning={loading} indicator={antIcon} tip="Carregando..."></Spin>

                    <Row justify="start">
                        <Col xs={24} sm={22} md={22} lg={22} xl={22}>
                            <Space size="small" style={{ display: 'flex' }}></Space>
                        </Col>
                        <Col xs={24} sm={2} md={2} lg={2} xl={2}>
                            <Form.Item></Form.Item>
                        </Col>
                    </Row>

                    <Row style={{ height: 60, marginTop: -50 }}>
                        <p style={{
                            fontSize: 28,
                            fontStyle: 'normal',
                            fontWeight: 700,
                            lineHeight: '4',
                            marginLeft: '5%',
                            color: '#101010'
                        }}>Pagamento</p>
                    </Row>
                    <Row>
                        <p style={{
                            marginLeft: '5%',
                            marginBottom: 30,
                            fontSize: 18,
                            fontStyle: 'normal',
                            lineHeight: '4',
                            color: '#101010'
                        }}>Efetuar Pagamento</p>
                    </Row>
                    <Form layout="vertical" > 
                        {/* <Row style={{ marginBottom: 10, marginTop: -20 }}>
                            <label style={{ marginLeft: '5%', fontSize: 15, fontWeight: 'bold', color: '#101010' }}>Pagador</label>
                        </Row> */}
                        <Row style={{ width: '90%', marginLeft: '5%', marginTop: -30, marginBottom: -20 }}>
                            <p style={{
                                color: '#262A41',
                                fontSize: 22,
                                width: '90%',
                                //paddingLeft: 20,
                                //marginLeft: '5%',
                                fontStyle: 'normal',
                                fontWeight: 400,
                                lineHeight: '106%',
                                marginBottom: 20,
                                //marginLeft: 10,
                                letterSpacing: 0.73,
                            }}>
                                Dados do Pagador
                            </p>
                        </Row>

                        {/* <Row style={{ marginBottom: 30, marginTop: 10 }}>
                            <label style={{ 
                                marginLeft: '5%', 
                                marginRight: 10,
                                fontSize: 15, 
                                backgroundColor: '#f5f5f5',
                                color: '#101010',
                                paddingTop: 9, 
                                paddingBottom: 9, 
                                paddingLeft: 15, 
                                width: '48%',
                                borderRadius: 8  }}
                            >
                                {pagador.toUpperCase()}
                            </label>
                            <Button
                                nativeProps={{
                                    ghost: true,
                                    style: {
                                        marginLeft: 10,
                                        height: 40,
                                        //padding: 20
                                    }
                                }}
                                text="Alterar Pagador"
                                onClick={() => setShowModalPayer(true)}
                            />  
                        </Row> */}

                        <Row style={{ width: '90%', marginLeft: '5%' }}>
                            <Col span={8} style={{ padding: 20, paddingLeft: 0 }}>
                                <Form.Item label="CPF/CNPJ">
                                    <InputMask
                                        mask={determineMaskDocument(document)}
                                        maskChar=""
                                        onChange={handleChangeDocument}
                                        disabled={isDisabled}
                                        style={{
                                            borderRadius: 8,
                                            height: 40,
                                            borderStyle: 'solid',
                                            borderWidth: 1,
                                            paddingLeft: 10,
                                            borderColor: error ? '#ff0000' : '#D9D9D9'
                                        }}
                                        value={document}
                                    />
                                </Form.Item>
                            </Col>
                            
                        </Row>

                        <Row style={{ width: '90%', marginLeft: '5%', marginBottom: -20 , marginTop: -50}}>
                            <Col span={14} style={{ padding: 20, paddingLeft: 0 }}>
                                <Form.Item label="Nome">
                                    <Input
                                        disabled={isDisabled}
                                        style={{
                                            height: 40,
                                            textTransform: 'uppercase',
                                            borderColor: error ? '#ff0000' : '#D9D9D9'
                                        }}
                                        placeholder="Informe o nome do pagador"
                                        onChange={(e) => setPagador(e.target.value)}
                                        value={pagador}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={10} style={{ padding: 20 }}>
                                <Form.Item label="E-mail" >
                                    <Input
                                        style={{
                                            height: 40,
                                            textTransform: 'lowercase',
                                            borderColor: error ? '#ff0000' : '#D9D9D9'
                                        }}
                                        disabled={isDisabled}
                                        placeholder="Informe o e-mail"
                                        onChange={(e) => setEmail(e.target.value)}
                                        value={email}
                                    />
                                </Form.Item>
                            </Col>                           
                        </Row>
                      
                        <Row style={{ width: '90%', marginLeft: '5%', marginTop: -10 }}>
                            <Col span={4} style={{ padding: 20, paddingLeft: 0 }}>
                                <Form.Item label="CEP" >
                                    <InputMask
                                        mask="99999-999"
                                        maskChar="_"
                                        onChange={(e) => setCep(e.target.value)}
                                        onBlur={async (e) => await handleZipCode(e.target.value)}
                                        disabled={isDisabled}
                                        style={{
                                            borderRadius: 8,
                                            height: 40,
                                            paddingLeft: 10,
                                            //marginLeft: 30,
                                            borderStyle: 'solid',
                                            borderWidth: 1,
                                            borderColor: error ? '#ff0000' : '#D9D9D9'
                                        }}
                                        value={cep}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={16} style={{ padding: 20 }}>
                                <Form.Item style={{ marginLeft: 30 }} label="Rua" >
                                    <Input
                                        style={{
                                            height: 40,
                                            //marginLeft: 30,
                                            textTransform: 'uppercase',
                                            borderColor: error ? '#ff0000' : '#D9D9D9'
                                        }}
                                        disabled={isDisabled}
                                        placeholder="Informe a rua"
                                        onChange={(e) => setAddress(e.target.value)}
                                        value={address}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={4} style={{ padding: 20, paddingLeft: 0 }}>
                                <Form.Item label="Número" >
                                    <Input
                                        style={{
                                            height: 40,
                                            textTransform: 'uppercase',
                                            borderColor: error ? '#ff0000' : '#D9D9D9'
                                        }}
                                        disabled={isDisabled}
                                        placeholder="Número"
                                        onChange={(e) => setNumberHome(e.target.value)}
                                        value={numberHome}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row style={{ width: '90%', marginLeft: '5%', marginTop: -50 }}>
                            <Col span={5} style={{ padding: 20, paddingLeft: 0 }}>
                                <Form.Item label="Complemento" >
                                    <Input
                                        disabled={isDisabled}
                                        style={{ height: 40, textTransform: 'uppercase' }}
                                        placeholder="Informe o complemento"
                                        onChange={(e) => setComplement(e.target.value)}
                                        value={complement}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={7} style={{ padding: 20 }}>
                                <Form.Item style={{ marginLeft: -10 }} label="Bairro" >
                                    
                                    <Input
                                        required
                                        style={{
                                            height: 40,
                                            textTransform: 'uppercase',
                                            borderColor: error ? '#ff0000' : '#D9D9D9'
                                        }}
                                        disabled={isDisabled}
                                        placeholder="Informe o bairro"
                                        onChange={(e) => setNeighborhood(e.target.value)}
                                        value={neighborhood}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={8} style={{ padding: 20 }}>
                                <Form.Item label="Cidade" >
                                    <Input
                                        style={{
                                            height: 40,
                                            textTransform: 'uppercase',
                                            borderColor: error ? '#ff0000' : '#D9D9D9'
                                        }}
                                        required
                                        disabled={isDisabled}
                                        placeholder="Informe a cidade"
                                        onChange={(e) => setCity(e.target.value)}
                                        value={city}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={4} style={{ padding: 20 }}>
                                <Form.Item label="Estado" >
                                    <Input
                                        style={{ height: 40, textTransform: 'uppercase', borderColor: error ? '#ff0000' : '#D9D9D9' }}
                                        placeholder="Informe o estado"
                                        onChange={(e) => handleStateChange(e.target.value)}
                                        disabled={isDisabled}
                                        value={state}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                         
                        <Row style={{ width: '90%', marginLeft: '5%', marginTop: -50 }}>
                            <Divider />
                        </Row>
                        <Row style={{ width: '90%', marginLeft: '5%', marginTop: -10 }}>
                            <p style={{
                                color: '#262A41',
                                fontSize: 22,
                                width: '90%',
                                //paddingLeft: 20,
                                //marginLeft: '5%',
                                fontStyle: 'normal',
                                fontWeight: 400,
                                lineHeight: '106%',
                                marginBottom: 20,
                                //marginLeft: 10,
                                letterSpacing: 0.73,
                            }}>
                                Dados do Pagamento
                            </p>
                        </Row>
                        <Row style={{ width: '90%', marginLeft: '5%' }}>
                           
                            <Col span={6} style={{ paddingRight: 20 }}>
                                <label style={{ fontSize: 15, fontWeight: 'bold', color: '#101010' }}>Opção de pagamento</label>
                                <Form.Item 
                                validateStatus={error ? 'error' : ''}
                                >
                                    <Select
                                        style={{
                                            height: 40,
                                            borderColor: error ? '#ff0000' : '#D9D9D9'
                                        }}
                                        placeholder="Selecione a forma de pagamento"
                                        options={paymentOptions}
                                        
                                        onChange={(e) => setPaymentType(e)}
                                        value={paymentType}
                                        disabled={items.filter((f) => f.statusInvoice === 'Solicitado' || f.statusInvoice === 'Emitido').length > 0 ? true : isDisabled}
                                    />
                                </Form.Item>
                            </Col>
                            {items[0]?.installments_number > 0 && paymentType === 'credit_card' && 
                                    <Col span={4} style={{ paddingRight: 20 }}>
                                        <label style={{ fontSize: 15, fontWeight: 'bold', color: '#101010' }}>Parcelas</label>
                                        <Form.Item>
                                            <Select
                                                style={{
                                                    height: 40,
                                                    borderColor:  '#D9D9D9'
                                                }}
                                                placeholder="Selecione as parcelas"
                                                options={installmentsNumber}
                                                onChange={(e) => setIntallentNumber(e)}
                                                disabled={isDisabled}
                                                value={intallentNumber}
                                            />
                                        </Form.Item>
                                    </Col>
                                }
                                <Col span={7} style={{ paddingRight: 20 }}>
                                    <label style={{ fontSize: 15, fontWeight: 'bold', color: '#101010' }}>{isCNPJ ? 'Valor Bruto' : 'Valor Total'}</label>
                                    <Form.Item>
                                        <Input
                                            type='Text'
                                            style={{
                                                height: 40,
                                                borderRadius: 8,
                                                width: '100%',
                                                borderColor: '#D9D9D9',
                                            }}
                                            value={formattedTotalValue}
                                        />
                                    </Form.Item>
                                </Col>
                                {isCNPJ && 
                                    <Col span={7} style={{ paddingRight: 20 }}>
                                        <label style={{ fontSize: 15, fontWeight: 'bold', color: '#101010' }}>Valor Líquido</label>
                                        <Form.Item>
                                            <Input
                                                type='Text'
                                                style={{
                                                    height: 40,
                                                    width: '100%',
                                                    borderColor: '#D9D9D9'
                                                }}
                                                value={formattedTotalValueToPay}
                                            />
                                        </Form.Item>
                                    </Col>
                                }
                        </Row>
                        { items.length > 1 && 
                         <Row style={{ marginBottom: 20 }}>
                            <label style={{ marginLeft: '5%', fontSize: 15, fontWeight: 'bold', color: '#101010' }}>Detalhes do Pagamento</label>
                            <textarea
                                rows={20}
                                style={{
                                    width: '90%',
                                    marginLeft: '5%',
                                    borderRadius: 8,
                                    marginTop: 10,
                                    marginBottom: 30,
                                    padding: 15,
                                    fontSize: 16,
                                    resize: 'none',
                                    borderColor: '#054B7C',
                                    color: '#101010'
                                }}
                                value={getDetalhes()}
                            >
                            </textarea>
                        </Row>
                        }
                        
                    </Form>

                    <Row style={{
                        marginBottom: 20,
                        width: '90%', marginLeft: '5%', marginTop: 40
                    }} className="justify-content-end">
                        <Button
                            text='Voltar'
                            nativeProps={{
                                ghost: true,
                                style: {
                                    
                                    height: 39,
                                    top: -2
                                }
                            }}
                            loading={loading}
                            onClick={() => navigate('/finance')}
                        />

                        <Button
                            text='Efetuar Pagamento'
                            nativeProps={{
                                style: {
                                    marginBottom: '5%',
                                    marginLeft: 'auto',
                                    marginRight: '20px',
                                }
                            }}
                            loading={loading}
                            onClick={() => handlePayment()}
                        />
                    </Row>

                </Content>
            </Content>
            {/* <Payer
                visible={showModalPayer}
                setVisible={setShowModalPayer}
                data={items}
                allData={items}
                setReload={()=>console.log()}
                setItemBase={()=>console.log()}
                setDataPayerError={setDataPayerError}
                dataPayerError={dataPayerError}
                setPagador={setPagador}
            /> */}
        </Layout>
    );
};


export default PaymentProcess;
